import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Card, Layout, Select, Typography } from "antd";
const { Title } = Typography;
const { Header, Content, Footer } = Layout;

const NotFound = () => (
  <div>
    <Layout>
      <div style={{ maxWidth: "1024px", margin: "300px auto" }}>
      <Content>
        <Card>
          <h1>Not sure how you ended up here...</h1>
          <Link to="/">Go back</Link>
        </Card>
      </Content>
      </div>
    </Layout>
  </div>
);

export default NotFound;