import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Container,
    Flex,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    SimpleGrid,
    Text,
  } from '@chakra-ui/react'
  import React from 'react'

//   const account = '0x5a379aaCf8Bf1E9D4E715D00654846eb1CFC8a76'

  // Takes a long hash string and truncates it.
  function truncateHash(hash, length = 38) {
    return hash.replace(hash.substring(6, length), '...')
  }

  /**
   * Component
   */
  export const Layout = ({children}) => {
    return (
      <>
        <header>
          <Container maxWidth="container.xl">
            <SimpleGrid
              columns={[1, 1, 1, 2]}
              alignItems="center"
              justifyContent="space-between"
              py="8"
            >
              <Flex
                  alignItems={'center'}
                  justifyContent={['center', null, null, 'flex-start']}
                  visibility={['hidden', null,  null,  'visible']}
                  >
                  <Link href="/#story" px="4" py="4" fontWeight="bold" fontSize={{ base: "12px", md: "14px", lg: "16px" }}>
                    STORY
                  </Link>
                  <Link href="/#art" px="4" py="4" fontWeight="bold" fontSize={{ base: "12px", md: "14px", lg: "16px" }}>
                    GENERATIVE
                  </Link>
                  <Link href="/#photography" px="4" py="4" fontWeight="bold" fontSize={{ base: "12px", md: "14px", lg: "16px" }}>
                    PHOTOGRAPHY
                  </Link>
                  <Link href="/#mint" px="4" py="4" fontWeight="bold" fontSize={{ base: "12px", md: "14px", lg: "16px" }}>
                    MINT
                  </Link>
              </Flex>
            </SimpleGrid>
          </Container>
        </header>
        <main>
          <Container maxWidth="container.xl">
            {children}
          </Container>
        </main>
        <footer>
          <Container mt="8" py="8" maxWidth="container.xl">
            
          </Container>
        </footer>
      </>
    )
  }
  