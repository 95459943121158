import { Button } from '@chakra-ui/react'
import React from "react";
import Address from "./Address";

export default function Account({
  address,
  mainnetProvider,
  minimized,
  web3Modal,
  loadWeb3Modal,
  logoutOfWeb3Modal,
  blockExplorer,
}) {

  return (
    <div>
      {minimized ? (
        ""
      ) : (
        <span>
          {web3Modal?.cachedProvider ? (
            <Address address={address} ensProvider={mainnetProvider} blockExplorer={blockExplorer} />
          ) : (
            ""
          )}
        </span>
      )}
      {web3Modal?.cachedProvider ? (
        <Button
          as="a"
          size="lg"
          colorScheme="blue"
          m="4"
          key="logoutbutton"
          onClick={logoutOfWeb3Modal}
        >
          Disconnect from&nbsp;<Address address={address} ensProvider={mainnetProvider} blockExplorer={blockExplorer} />
        </Button>
      ) : (
        <Button
          as="a"  
          key="loginbutton"
          m="4"
          size="lg"
          colorScheme="teal" 
          variant="outline"  
          /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          onClick={loadWeb3Modal}
        >
          Connect Wallet
        </Button>
      )}
    </div>
  );
}
