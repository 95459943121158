import { Link, Box, Button, Divider, Heading, Text, SimpleGrid, Center, Stack, UnorderedList, ListItem, Container, Flex, Image, Input } from '@chakra-ui/react'
import {Select, Table,Thead,Tbody,Tfoot,Tr,Th,Td,TableCaption} from "@chakra-ui/react"

import {Layout} from './components/layout/Layout'
import { Account } from "./components";

const sampleBanner = './assets/example3.png'
const sampleBannerPlaceholder = './assets/example3_placeholder.png'
const sampleGif = './assets/txGeneration.gif'
const bannedLogoFirstFrame = './assets/banned_logo_sm_ff.png'
const bannedLogoFirstFrame2 = './assets/banned_logo3_sm_ff.png'
const bannedLogoGif = './assets/bannedlogosm.gif'
const bannedLogoGif2 = './assets/bannedlogo3sm.gif'
const jake1 = './assets/jake1.jpeg'
const jake2 = './assets/jake2.jpeg'
const jake3 = './assets/jake3.jpeg'
const chip1 = './assets/chip1.jpeg'
const chip2 = './assets/chip2.jpeg'
const chip3 = './assets/chip3.jpeg'
const joey1 = './assets/joey1.jpeg'
const joey2 = './assets/joey2.jpeg'
const joey3 = './assets/joey3.jpeg'
const ex1 = './assets/1.jpg'
const ex2 = './assets/2.jpg'
const ex3 = './assets/3.jpg'
const ex4 = './assets/4.jpg'
const ex5 = './assets/5.jpg'
const ex6 = './assets/6.jpg'
const theStart = './assets/thestart.jpg'
const roadmap = './assets/roadmap.jpg'
const andrew = './assets/andrew.jpeg'
const joey = './assets/joey.jpeg'
const jake = './assets/jake.jpeg'
const richerd = './assets/richerd.jpeg'
const chip = './assets/chip.jpeg'
const opensea = './assets/opensea.png'
const discord = './assets/discord.png'
const twitter = './assets/twitter.png'
import styles from './styles/Index.module.css'

import banned from "./whitelists/banned";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Alert } from "antd";
import "antd/dist/antd.css";
import {
  useContractLoader,
  useContractReader,
  useGasPrice,
  useOnBlock,
  useUserProviderAndSigner,
} from "eth-hooks";
import { useExchangeEthPrice } from "eth-hooks/dapps/dex";
import React, { useCallback, useEffect, useState } from "react";
import Web3Modal from "web3modal";
import "./App.css";
import { INFURA_ID, NETWORK, NETWORKS, ALCHEMY_KEY } from "./constants";
import externalContracts from "./contracts/external_contracts";
// contracts
import deployedContracts from "./contracts/hardhat_contracts.json";
import { Transactor } from "./helpers";

const { ethers, utils } = require("ethers");
const { MerkleTree } = require("merkletreejs");
const keccak256 = require("keccak256");

const targetNetwork = NETWORKS.mainnet; // <------- select your target frontend network (localhost, rinkeby, xdai, mainnet)

const DEBUG = false;
const NETWORKCHECK = true;

const scaffoldEthProvider = navigator.onLine
  ? new ethers.providers.StaticJsonRpcProvider("https://rpc.scaffoldeth.io:48544")
  : null;
const poktMainnetProvider = navigator.onLine
  ? new ethers.providers.StaticJsonRpcProvider(
      "https://eth-mainnet.gateway.pokt.network/v1/lb/61853c567335c80036054a2b",
    )
  : null;
const mainnetInfura = navigator.onLine
  ? new ethers.providers.StaticJsonRpcProvider(`https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_KEY}`)
  : null;
// ( ⚠️ Getting "failed to meet quorum" errors? Check your INFURA_ID
// 🏠 Your local provider is usually pointed at your local blockchain
const localProviderUrl = targetNetwork.rpcUrl;
// as you deploy to other networks you can set REACT_APP_PROVIDER=https://dai.poa.network in packages/react-app/.env
const localProviderUrlFromEnv = process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : localProviderUrl;
const localProvider = new ethers.providers.StaticJsonRpcProvider(localProviderUrlFromEnv);

// 🔭 block explorer URL
const blockExplorer = targetNetwork.blockExplorer;

// Portis ID: 6255fb2b-58c8-433b-a2c9-62098c05ddc9
/*
  Web3 modal helps us "connect" external wallets:
*/
const web3Modal = new Web3Modal({
  network: "mainnet", // optional. If using WalletConnect on xDai, change network to "xdai" and add RPC info below for xDai chain.
  cacheProvider: true, // optional
  theme: "light", // optional. Change to "dark" for a dark theme.
  provideroptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        bridge: "https://polygon.bridge.walletconnect.org",
        infuraId: INFURA_ID,
        rpc: {
          1: `https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_KEY}`, // mainnet // For more WalletConnect providers: https://docs.walletconnect.org/quick-start/dapps/web3-provider#required
          42: `https://kovan.infura.io/v3/${INFURA_ID}`,
          100: "https://dai.poa.network", // xDai
        },
      },
    },
  },
});

function App(props) {
  const mainnetProvider =
    poktMainnetProvider && poktMainnetProvider._isProvider
      ? poktMainnetProvider
      : scaffoldEthProvider && scaffoldEthProvider._network
      ? scaffoldEthProvider
      : mainnetInfura;

  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState();

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  const price = useExchangeEthPrice(targetNetwork, mainnetProvider);
  const gasPrice = useGasPrice(targetNetwork, "fast");
  const userProviderAndSigner = useUserProviderAndSigner(injectedProvider, localProvider);
  const userSigner = userProviderAndSigner.signer;

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress);
      }
    }
    getAddress();
  }, [userSigner]);

  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId =
    userSigner && userSigner.provider && userSigner.provider._network && userSigner.provider._network.chainId;

  const tx = Transactor(userSigner, gasPrice * 1.25);
  const contractConfig = { deployedContracts: deployedContracts || {}, externalContracts: externalContracts || {} };
  const readContracts = useContractLoader(localProvider, contractConfig);
  window.readContracts = readContracts;
  const writeContracts = useContractLoader(userSigner, contractConfig, localChainId);
  window.writeContracts = writeContracts;

  // If you want to call a function on a new block
  useOnBlock(mainnetProvider, () => {
    
  });

  // const totalSupply0 = parseInt(useContractReader(readContracts, "LilFranksMintPassportFactory", "totalSupply", ["0"]));
  // const totalSupply1 = parseInt(useContractReader(readContracts, "LilFranksMintPassportFactory", "totalSupply", ["1"]));

  const isFreeMintOpen = useContractReader(readContracts, "Banned", "redemptionWindows", ["0"]);
  window.isFreeMintOpen = isFreeMintOpen;
  const isPublicMintOpen = useContractReader(readContracts, "Banned", "redemptionWindows", ["1"]);
  window.isPublicMintOpen = isPublicMintOpen;

  let networkDisplay = "";
  if (NETWORKCHECK && localChainId && selectedChainId && localChainId !== selectedChainId) {
    const networkSelected = NETWORK(selectedChainId);
    const networkLocal = NETWORK(localChainId);
    if (selectedChainId === 1337 && localChainId === 31337) {
      networkDisplay = (
        <div style={{ zIndex: 2, position: "absolute", right: 0, top: 60, padding: 16 }}>
          <Alert
            message="⚠️ Wrong Network ID"
            description={
              <div>
                You have <b>chain id 1337</b> for localhost and you need to change it to <b>31337</b> to work with
                HardHat.
                <div>(MetaMask -&gt; Settings -&gt; Networks -&gt; Chain ID -&gt; 31337)</div>
              </div>
            }
            type="warning"
            closable={false}
          />
        </div>
      );
    } else {
      networkDisplay = (
        <div style={{ zIndex: 2, position: "absolute", right: 0, top: 60, padding: 16 }}>
          <Alert
            message="⚠️ Change Network"
            description={
              <div>
                Switch your network to {" "}
                <Button
                  onClick={async () => {
                    const ethereum = window.ethereum;
                    const data = [
                      {
                        chainId: "0x" + targetNetwork.chainId.toString(16),
                        chainName: targetNetwork.name,
                        nativeCurrency: targetNetwork.nativeCurrency,
                        rpcUrls: [targetNetwork.rpcUrl],
                        blockExplorerUrls: [targetNetwork.blockExplorer],
                      },
                    ];
                    

                    let switchTx;
                    // https://docs.metamask.io/guide/rpc-api.html#other-rpc-methods
                    try {
                      switchTx = await ethereum.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: data[0].chainId }],
                      });
                    } catch (switchError) {
                      // not checking specific error code, because maybe we're not using MetaMask
                      try {
                        switchTx = await ethereum.request({
                          method: "wallet_addEthereumChain",
                          params: data,
                        });
                      } catch (addError) {
                        // handle "add" error
                      }
                    }

                    if (switchTx) {
                      console.log(switchTx);
                    }
                  }}
                >
                  <b>{networkLocal && networkLocal.name}</b>
                </Button>
              </div>
            }
            type="warning"
            closable={false}
          />
        </div>
      );
    }
  } else {
    networkDisplay = (
      <div style={{ zIndex: -1, position: "absolute", right: 154, top: 28, padding: 16, color: targetNetwork.color }}>
        {targetNetwork.name}
      </div>
    );
  }

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", chainId => {
      console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on("accountsChanged", () => {
      console.log(`account changed!`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
    });
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  const [route, setRoute] = useState();
  useEffect(() => {
    setRoute(window.location.pathname);
  }, [setRoute]);

  const [mintAmount, setMintAmount] = useState("1");

  function handleSelectChange(value) {
    setMintAmount(value.target.value);
  }

  function hashToken(account) {
    return ethers.utils.solidityKeccak256(["address"], [account]);
  }

  const tree = new MerkleTree(
    banned.map(token => hashToken(token)),
    keccak256,
    { sortPairs: true },
  );
  const root = tree.getHexRoot();
  console.log("root:", root);

  const showProof = async () => {
    const isSaleOpen = await readContracts.Banned.redemptionWindows("0");
    if (!isSaleOpen["open"]) {
      alert("The free mint is not open yet");
      return;
    }
    const totalMinted = await readContracts.Banned.balanceOf(address);
    if (totalMinted && parseInt(totalMinted) >= 1) {
      alert("You've already minted your free mint");
      return;
    }
    if (selectedChainId !== localChainId) {
      alert("Please switch to the right network");
      return;
    }
    if (address && web3Modal?.cachedProvider) {
      const node = hashToken(address);
      const proof = tree.getHexProof(node);
      tx(
        writeContracts.Banned.mint(0, 1, proof),
      );
    } else {
      alert("Please connect your wallet");
    }
  };

  const publicMint = async () => {
    const isSaleOpen = await readContracts.Banned.redemptionWindows("1");
    if (!isSaleOpen["open"]) {
      alert("The public mint is not open yet");
      return;
    }
    if (selectedChainId !== localChainId) {
      alert("Please switch to the right network");
      return;
    }
    if (address && web3Modal?.cachedProvider) {
      const node = hashToken(address);
      const proof = tree.getHexProof(node);
      tx(
        writeContracts.Banned.mint(1, mintAmount, proof, {
          value: utils.parseEther(String(0.04 * mintAmount)),
        }),
      );
    } else {
      alert("Please connect your wallet");
    }
  };

  return (
    <Layout>
      <div style={{ position: "fixed", textAlign: "right", right: 20, top: 20, padding: 10 }}>
        <Account
          address={address}
          localProvider={localProvider}
          userSigner={userSigner}
          mainnetProvider={mainnetProvider}
          price={price}
          web3Modal={web3Modal}
          loadWeb3Modal={loadWeb3Modal}
          logoutOfWeb3Modal={logoutOfWeb3Modal}
          blockExplorer={blockExplorer}
          style={{ fontSize: "16px" }}
          minimized={true}
        />
      </div>
      <Center>
        <Box maxWidth="container.lg">
          <Image src={bannedLogoGif} fallbackSrc={bannedLogoFirstFrame} />
        </Box>
      </Center>
      <Center>
        <Box maxWidth="container.lg" p="8" mt="8" mb="8" bg="gray.800">
          <Image src={sampleBanner} fallbackSrc={sampleBannerPlaceholder} alt="BANNED" />
        </Box>
      </Center>
      <Center id="mint">
        <Box maxWidth="container.lg" mt="8" mb="8">
          <Text fontSize="24" lineHeight="2" textAlign="center">
          BANNED is a generative art NFT project seeded with the unique photography of <Link color="purple.500" href="https://twitter.com/jakethedegen">@JakeTheDegen</Link>, <Link color="purple.500" href="https://twitter.com/iJmillz">@iJmillz</Link>, and <Link color="purple.500" href="https://twitter.com/chipwalkernft">@ChipWalkerNFT</Link>. It is a declaration that community and greatness stems from what one does in the face of adversity.
          <br/><br/>
          The goal of this collection is to support rising artists in the NFT photography community. 50% of all proceeds will be used to onboard photographers to their own smart contract, giving them true provenance on the blockchain, as well as collecting work from emerging photographers.</Text>
          </Box>
      </Center>
      <Center>
        <Box maxWidth="container.sm">
          <Select placeholder="1" defaultValue="1" onChange={handleSelectChange}>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
          </Select>
          
          {isPublicMintOpen ?
            <Button as="a" size="lg" colorScheme="blue" m="4" onClick={publicMint}>
              Public Mint ({String(0.04 * mintAmount)} ETH)
            </Button>
            : <Button disabled="true" as="a" size="lg" colorScheme="blue" m="4">
              Public Mint Not Active
            </Button>
          }
        </Box>
      </Center>
      <Divider my="10" />
      <Center>
        <Heading id="story" as="h2" mb="8" mt="16" fontSize="36" className={styles.rocksalt} textAlign="center">
          The Story
        </Heading>
      </Center>
      <Container maxWidth="container.xl">
          <SimpleGrid
            columns={[1, 1, 1, 2]}
            alignItems="center"
            justifyContent="space-between"
            py="8"
          >
            <Flex
                alignItems={'center'}
                justifyContent={['center', null, null, 'center']}>
              <Box maxWidth="container.sm" width={400} p="8" mt="8" mb="8" bg="gray.800" >
                <Image src={theStart} alt="The Start" />
              </Box>
            </Flex>
            
            <Flex
                alignItems={'center'}
                justifyContent={['center', null, null, 'flex-end']}>
              <Text fontSize="24" lineHeight="2" textAlign="center">
                BANNED started in a moment of chaos. We were brought together by a shared love of art and community.
                <br/><br/>
                Free to mint for all 9021 minters, with 50% of both primary and secondary proceeds going to help emerging photographers - both to cover costs to deploy their own smart contracts and to buy photography to the BANNED community vault.
              </Text>
            </Flex>
          </SimpleGrid>
      </Container>
      <Center>
        
      </Center>
      <Divider my="10" />
      <Center>
        <Heading id="art" as="h2" mb="8" mt="16" fontSize="36" className={styles.rocksalt} textAlign="center">
          Art Generation
        </Heading>
      </Center>
      <Center >
        <Text fontSize="24" mb="8" lineHeight="1.6" textAlign="center">
          Each BANNED is generated from a random seed from which the rest of the p5.js script runs on. Three works of photography are randomly selected, as are the generative filters applied, the pixel size, and various colors of borders and backgrounds. There are a number of rare attributes, randomly generated in the final result.
        </Text>
      </Center>
      <Center>
        <Box maxWidth="container.lg" p="8" mt="8" mb="8" bg="gray.800">
          <Image src={sampleGif} alt="TX Generation" />
        </Box>
      </Center>
      <Divider my="10" />
      <Center>
        <Heading id="photography" as="h2" mb="8" mt="16" fontSize="36" className={styles.rocksalt} textAlign="center">
          The Photography
        </Heading>
      </Center>
      <Center>
        <Stack direction={["column","column","column","row"]} height={[1200,1200,1200,400]} mb="2">
          <div className={styles.jake1bg}>
            <Image src={jake1} alt="Sample Art" width={400} height={400} objectFit="cover" className={styles.galleryPhoto}/>
          </div>
          <div className={styles.jake3bg}>
            <Image src={jake3} alt="Sample Art" width={400} height={400} objectFit="cover" className={styles.galleryPhoto}/>
          </div>
          <div className={styles.jake2bg}>
            <Image src={jake2} alt="Sample Art" width={400} height={400} objectFit="cover" className={styles.galleryPhoto}/>
          </div>
        </Stack>
      </Center>
      <Center>
        <Stack direction={["column","column","column","row"]} height={[1200,1200,1200,400]} mb="2">
          <div className={styles.chip1bg}>
            <Image src={chip1} alt="Sample Art" width={400} height={400} objectFit="cover" className={styles.galleryPhoto}/>
          </div>
          <div className={styles.chip2bg}>
            <Image src={chip2} alt="Sample Art" width={400} height={400} objectFit="cover" className={styles.galleryPhoto}/>
          </div>
          <div className={styles.chip3bg}>
            <Image src={chip3} alt="Sample Art" width={400} height={400}  objectFit="cover" className={styles.galleryPhoto}/>
          </div>
        </Stack>
      </Center>
      <Center>
        <Stack direction={["column","column","column","row"]} height={[1200,1200,1200,400]}>
          <div className={styles.joey1bg}>
            <Image src={joey1} alt="Sample Art" width={400} height={400}  objectFit="cover" className={styles.galleryPhoto}/>
          </div>
          <div className={styles.joey2bg}>
            <Image src={joey2} alt="Sample Art" width={400} height={400}  objectFit="cover" className={styles.galleryPhoto}/>
          </div>
          <div className={styles.joey3bg}>
            <Image src={joey3} alt="Sample Art" width={400} height={400}  objectFit="cover" className={styles.galleryPhoto}/>
          </div>
        </Stack>
      </Center>
      <Divider my="10" />
      <Center>
        <Heading as="h2" mb="8" mt="16" fontSize="36" className={styles.rocksalt} textAlign="center">
          For 9021 Minters
        </Heading>
        
      </Center>
      <Center>
        <Text fontSize="24" mb="8" textAlign="center">
          BANNED started in the midst of the chaos of 9021, but has no association with the project. We want to show the true spirit of the NFT community: build unique art, grow a great community, and support artists.
          <br/><br/>
          For the people that minted 9021 (snapshot taken on August 21st, 2021), you'll always have a free mint of BANNED. Your allocation is reserved, so no need to fight a gas war to get yours!
        </Text>
      </Center>
      <Center>
        <Box maxWidth="container.sm" bg="gray.800">
          {isFreeMintOpen ?
            <Button as="a" size="lg" colorScheme="blue" m="4" onClick={showProof}>
              Presale Mint
            </Button>
            : <Button disabled="true" as="a" size="lg" colorScheme="blue" m="4">
              Presale Not Active
            </Button>
          }
        </Box>
      </Center>
      <Divider my="10" />
      <Center>
        <Heading as="h2" mb="8" mt="16" fontSize="36" className={styles.rocksalt} textAlign="center">
          Sample Mints
        </Heading>
      </Center>
      <Center>
        <Box maxWidth="container.lg" p="8" mt="8" mb="8" bg="gray.800">
          <Image src={ex1} placeholder="blur" alt="BANNED" />
          <Image src={ex2} placeholder="blur" alt="BANNED" />
          <Image src={ex3} placeholder="blur" alt="BANNED" />
          <Image src={ex5} placeholder="blur" alt="BANNED" />
          <Image src={ex6} placeholder="blur" alt="BANNED" />
        </Box>
      </Center>
      <Divider my="10" />
      <Center id="attributes">
        <Heading as="h2" mb="8" mt="16" fontSize="36" className={styles.rocksalt} textAlign="center">
          Attributes and Rarities
        </Heading>
      </Center>
      <Center>
        <Text fontSize="14" mb="8" textAlign="center">
          *not yet final
        </Text>
      </Center>
      <Center>
      <Table variant="striped" colorScheme="purple">
        <TableCaption>BANNED Metadata Properties</TableCaption>
        <Thead>
          <Tr>
            <Th>Property</Th>
            <Th>Value</Th>
            <Th isNumeric>Estimated Count</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Artist</Td>
            <Td>Jake, Joey, or Chip Walker</Td>
            <Td isNumeric>31% Each</Td>
          </Tr>
          <Tr>
            <Td></Td>
            <Td>Guest Artists</Td>
            <Td isNumeric>6.3%</Td>
          </Tr>

          <Tr>
            <Td>Generative Effects</Td>
            <Td>Half Tone, Tiles, Bleeding Stripes, VCR, Growing Pixels</Td>
            <Td isNumeric>25% Each</Td>
          </Tr>
          <Tr>
            <Td></Td>
            <Td>Rare Effects</Td>
            <Td isNumeric>Less than 1%</Td>
          </Tr>

          <Tr>
            <Td>Base Pixel Size</Td>
            <Td>8 to 80</Td>
            <Td isNumeric>90%</Td>
          </Tr>
          <Tr>
            <Td></Td>
            <Td>4, 5, 100, 200</Td>
            <Td isNumeric>10%</Td>
          </Tr>

          <Tr>
            <Td>Background</Td>
            <Td>Blue, Green, Purple, Red</Td>
            <Td isNumeric>90%</Td>
          </Tr>
          <Tr>
            <Td></Td>
            <Td>Black, White</Td>
            <Td isNumeric>10%</Td>
          </Tr>

          <Tr>
            <Td>All Three Main Artists</Td>
            <Td>Jake + Joey + Chip</Td>
            <Td isNumeric>16%</Td>
          </Tr>
          <Tr>
            <Td></Td>
            <Td>Single Artists Only</Td>
            <Td isNumeric>8.1%</Td>
          </Tr>
          <Tr>
            <Td></Td>
            <Td>Guest Artists Only</Td>
            <Td isNumeric>0.1%</Td>
          </Tr>
          <Tr>
            <Td></Td>
            <Td>Single Photo Only</Td>
            <Td isNumeric>0.003%</Td>
          </Tr>
          
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>Property</Th>
            <Th>Value</Th>
            <Th isNumeric>Estimated Rarity</Th>
          </Tr>
        </Tfoot>
      </Table>
      </Center>
      <Divider my="10" />
      <Center id="attributes">
        <Heading as="h2" mb="8" mt="16" fontSize="36" className={styles.rocksalt} textAlign="center">
          TEAM
        </Heading>
      </Center>
      <Center>
        <Text fontSize="24" mb="8" textAlign="center">
          No anons, just real human beings trying their best.
        </Text>
      </Center>
      <SimpleGrid minChildWidth="150px" columns={5} spacing={10}>
        <Box>
          <Image src={andrew} alt="Andrew" className={styles.pfp} />
          <Center>    
            <Link href="https://twitter.com/andrewjiang" color="purple.500" >
              <Text fontSize="xl" textAlign="center">
                @andrewjiang
              </Text>
            </Link>
          </Center>
          <Center>    
            <Text fontSize="md" textAlign="center" mb="8">
              Dev Lead *
            </Text>
          </Center>
        </Box>
        <Box>
          <Image src={jake} alt="Jake" className={styles.pfp} />
          <Center>   
            <Link href="https://twitter.com/jakethedegen" color="purple.500" > 
              <Text fontSize="xl" textAlign="center">
                @jakethedegen
              </Text>
            </Link>
          </Center>
          <Center>    
            <Text fontSize="md" textAlign="center" mb="8">
              Art Lead
            </Text>
          </Center>
        </Box>
        <Box>
          <Image src={joey} alt="Joey" className={styles.pfp} />
          <Center>  
            <Link href="https://twitter.com/iJmillz" color="purple.500" >  
              <Text fontSize="xl" textAlign="center">
                @iJmillz
              </Text>
            </Link>
          </Center>
          <Center>    
            <Text fontSize="md" textAlign="center" mb="8">
              Artist
            </Text>
          </Center>
        </Box>
        <Box>
          <Image src={chip} alt="Chip" className={styles.pfp} />
          <Center>    
            <Link href="https://twitter.com/ChipWalkerNFT" color="purple.500" >
              <Text fontSize="xl" textAlign="center">
                @ChipWalkerNFT
              </Text>
            </Link>
          </Center>
          <Center>    
            <Text fontSize="md" textAlign="center" mb="8">
              Artist
            </Text>
          </Center>
        </Box>
        <Box>
          <Image src={richerd} alt="Richerd" className={styles.pfp} />
          <Center>   
            <Link href="https://twitter.com/richerd" color="purple.500"> 
              <Text fontSize="xl" textAlign="center">
                @richerd
              </Text>
            </Link>
          </Center>
          <Center>    
            <Text fontSize="md" textAlign="center" mb="8">
              Advisor
            </Text>
          </Center>
        </Box>
        
      </SimpleGrid>
      <Center>
        <Text fontSize="xl" mb="8" textAlign="center">
          *Only person to blame if something breaks
        </Text>
      </Center>
      <Divider my="10" />      
      <Center>
        <Box maxWidth="container.sm" mt="8" mb="8">
          <Image src={roadmap} alt="Roadmap" />
        </Box>
      </Center>
      <Center>
        <Text fontSize="24" mb="8" textAlign="center">
          First we're going to deliver the art.
          <br/><br/>
          And then we're going to help other artists bring more art to the world.
          <br/><br/>
          After that we're going to buy some art.
        </Text>
      </Center>
      <Divider my="10" />
      <Center>
        <Heading as="h2" mb="8" mt="16" fontSize="36" className={styles.rocksalt}>ATTRIBUTION</Heading>
      </Center>
      <Center>
        <Text fontSize="24" mb="8" textAlign="center">
          This project, like many NFT projects, stands on the shoulders of giants. 
          <br/><br/>
          We proudly attribute them for their contribution to our project and many others.
        </Text>
      </Center>
      <Center>
        <UnorderedList>
          <ListItem>
            <Link color="purple.300" href="https://p5js.org/">Generative art written in p5.js</Link>
          </ListItem>
          <ListItem>
            <Link color="purple.300" href="https://github.com/austintgriffith/scaffold-eth/tree/nextjs-typescript">scaffold-eth/tree/nextjs-typescript</Link>
            {' '}by{' '}
            <Link color="purple.300" href="https://twitter.com/huntarosan">Hunter Chang</Link>
          </ListItem>
          <ListItem>
            <Link color="purple.300" href="https://thenounproject.com/aidenopoly/collection/hand-drawn-arrows/">Hand drawn arrow by Aiden Regalado (Noun Project)</Link>
          </ListItem>
        </UnorderedList>
      </Center>
      <Divider my="10" />
      <Center>
        <Image src={bannedLogoGif2} fallbackSrc={bannedLogoFirstFrame2} />
      </Center>
      <Center>
        <Box maxWidth="container.sm" >
          <Link href="https://twitter.com/bannednft">
            <Image src={twitter} className={styles.social} />
          </Link>
          <Link href="https://discord.gg/XfFxeqwXeV">
            <Image src={discord} className={styles.social} />
          </Link>
          <Link href="https://opensea.io/collection/bannednft">
            <Image src={opensea} className={`${styles.social} ${styles.disabled}`} />
          </Link>
        </Box>
      </Center>
    </Layout>

        // <div className="App">
    //   <select defaultValue="1" size="large" onChange={handleSelectChange}>
    //     <option value="1">1</option>
    //     <option value="2">2</option>
    //     <option value="3">3</option>
    //     <option value="4">4</option>
    //     <option value="5">5</option>
    //     <option value="6">6</option>
    //     <option value="7">7</option>
    //     <option value="8">8</option>
    //     <option value="9">9</option>
    //     <option value="10">10</option>
    //     <option value="11">11</option>
    //     <option value="12">12</option>
    //     <option value="13">13</option>
    //     <option value="14">14</option>
    //     <option value="15">15</option>
    //     <option value="16">16</option>
    //     <option value="17">17</option>
    //     <option value="18">18</option>
    //     <option value="19">19</option>
    //     <option value="20">20</option>

    //   </select>
   
    // </div>

  );
}

export default App;
