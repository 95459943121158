import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react'
import App from "./App";
// import Redeem from "./Redeem";
import NotFound from "./NotFound"
// import IndexPage from "./Home";
import "./index.css";
import theme from "./theme"

const customTheme = {
  ...theme,
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: "black",
        color: "white",
      },
      h2: {
        color: "white",
      }
    },
  },
  colors: {
    ...theme.colors,
    purple: {
      50: '#f5e9ff',
      100: '#dac1f3',
      200: '#c098e7',
      300: '#a571dc',
      400: '#8c48d0',
      500: '#722fb7',
      600: '#59238f',
      700: '#3f1968',
      800: '#260f40',
      900: '#10031a',
    }
  }
}

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* <Route exact path="/">
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={prevTheme || "light"}>
          <App/>
        </ThemeSwitcherProvider>
      </Route>
      <Route exact path="/redeem">
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={prevTheme || "light"}>
          <Redeem />
        </ThemeSwitcherProvider>
      </Route> */}
      <Route exact path="/">
        <ChakraProvider theme={customTheme}>
          <App/>
        </ChakraProvider>
      </Route>
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root"),
);
