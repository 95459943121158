// theme.js
import { extendTheme, ThemeConfig } from "@chakra-ui/react"
import { createBreakpoints } from "@chakra-ui/theme-tools"

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
}

const breakpoints = createBreakpoints({
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px"
})

// Version 1: Using objects
const theme = extendTheme({
  config,
  breakpoints
})

export default theme;
